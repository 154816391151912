<header class="row">
  <div class="col-md-12 title" [ngClass]="{ showButton: showButton }">
    <button (click)="back()" size="lg" uni-icon-button variant="primary" class="back-arrow" *ngIf="showButton">
      <uni-icon name="chevron-left"></uni-icon>
    </button>

    <h1 color="primary" uni-heading alignText="center">
      <div *ngIf="title">
        {{ title }}
      </div>
      <div *ngIf="!title">
        <ng-content></ng-content>
      </div>
    </h1>
    <button
      *ngIf="showButton"
      uni-button
      [iconReverse]="true"
      variant="info"
      icon="chevron-left"
      class="btn-slim right-back-button"
      (click)="back()"
    >
      {{ backButtonText }}
    </button>
  </div>
  <div *ngIf="subTitle" class="col-md-12 mt-3">
    <h2 uni-heading alignText="center" color="lighten" visualLevel="h3">{{ subTitle }}</h2>
  </div>
</header>
