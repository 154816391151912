import { Component, Input } from "@angular/core";

import { AuthService } from "@shared/Services/auth.service";

@Component({
  selector: "app-inicio-header",
  templateUrl: "./inicio-header.component.html",
  styleUrls: ["./inicio-header.component.scss"],
})
export class InicioHeaderComponent {
  public mobileMenu: boolean;
  public readonly LOGOS = {
    Universal: { logo: "assets/images/universal-logo.svg", url: "/" },
    ARS: { logo: "assets/images/logo-ars-universal.svg", url: "https://www.arsuniversal.com.do/" },
  };

  public logoValue = this.LOGOS["Universal"];

  @Input() public set logo(value: string) {
    this.logoValue = this.LOGOS[value] || this.LOGOS["Universal"];
  }

  constructor(private authService: AuthService) {}

  public login(): void {
    this.authService.login();
  }

  public toggleMenu(): void {
    this.mobileMenu = !this.mobileMenu;
  }
}
