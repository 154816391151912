import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NgSelectConfig } from "@ng-select/ng-select";
import { SvgIconRegistryService } from "angular-svg-icon";
import { setTheme } from "ngx-bootstrap/utils";
import { Observable } from "rxjs";
import { appInsight } from "src/shared/Services/appInsight.service";
import { AuthService } from "src/shared/Services/auth.service";

import mensajes from "@assets/constantes/mensajes.json";

import { FeatureFlagService } from "../shared/Services/featureFlag.service";
import { Inactivity } from "../shared/Services/inactivity.service";

import { HeaderService } from "./Header/header.service";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  public toggledSidebar = false;
  public isUserSignedIn: boolean = false;
  public hideUserHeader: boolean = false;
  //New Auth Service
  public isAuthenticated: Observable<boolean>;
  public isDoneLoading: Observable<boolean>;
  public canActivateProtectedRoutes: Observable<boolean>;

  constructor(
    private titleService: Title,
    private inactivityService: Inactivity,
    private featureFlagService: FeatureFlagService,
    private headerService: HeaderService,
    private ngSelectConfig: NgSelectConfig,
    private appInsightService: appInsight,
    private authService: AuthService,
    private svgIconRegistryService: SvgIconRegistryService
  ) {
    this.authService.runInitialLoginSequence();

    setTheme("bs4");

    this.headerService.routesData$.subscribe((data) => {
      if (data.length < 2) {
        return this.titleService.setTitle("Universal en línea");
      }
      const title = data.slice(-2).reduce((acc, curr) => (acc ? curr.title + " - " + acc : curr.title), "");
      this.titleService.setTitle(title);
    });

    this.appInsightService.initialize();

    //Ng Select names translation
    Object.assign(this.ngSelectConfig, mensajes.componentes.dropdown);

    this.svgIconRegistryService.loadSvg("../assets/images/icons/historical.svg", "historical");
    this.svgIconRegistryService.loadSvg("../assets/images/icons/visa-card.svg", "visa");
    this.svgIconRegistryService.loadSvg("../assets/images/icons/mastercard-card.svg", "master-card");
    this.svgIconRegistryService.loadSvg("../assets/images/icons/american-express-card.svg", "american-express");
    this.svgIconRegistryService.loadSvg("../assets/images/icons/file-checked.svg", "file-checked");
    this.svgIconRegistryService.loadSvg("../assets/images/icons/file-icon.svg", "file");
    this.svgIconRegistryService.loadSvg("../assets/images/icons/select-icon.svg", "select");

    // Menu
    this.svgIconRegistryService.loadSvg("../assets/images/menu/afi-icon.svg", "afi-menu");
    this.svgIconRegistryService.loadSvg("../assets/images/menu/salas-vip-icon.svg", "salas-vip-menu");
    this.svgIconRegistryService.loadSvg("../assets/images/menu/canales-icon.svg", "canales-menu");
    this.svgIconRegistryService.loadSvg("../assets/images/menu/contact-icon.svg", "contact-menu");
    this.svgIconRegistryService.loadSvg("../assets/images/menu/rgvsi-icon.svg", "rgvsi-menu");
    this.svgIconRegistryService.loadSvg("../assets/images/menu/home-icon.svg", "home-menu");
    this.svgIconRegistryService.loadSvg("../assets/images/menu/salud-local-icon.svg", "salud-local-menu");
  }

  public ngOnInit(): void {
    this.featureFlagService.initialize();
  }

  public onSidebarToggled(): void {
    this.toggledSidebar = !this.toggledSidebar;
  }

  public ngOnDestroy(): void {
    this.inactivityService.clearInactivityInterval();
    this.inactivityService.clearEventListener();
  }
}
