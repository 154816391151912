import { Injectable } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, of } from "rxjs";
import { first } from "rxjs/operators";

import { ModalTitles, ModalType } from "@shared/Constantes/modal.constantes";

import { ConfirmGeneral } from "../../app/General/Confirm/confirm.component";
import { ModalGeneralComponent } from "../../app/General/Modal/modal.component";
import { Modal } from "../Entities/General/modal.entity";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private modalConfirmGeneral = null;

  constructor(private modalService: BsModalService) {}

  public showModalGeneral(mensaje: Modal, staticBackdrop: boolean = false): Observable<boolean> {
    if (this.modalService.getModalsCount() < 1) {
      this.modalConfirmGeneral = this.modalService.show(ModalGeneralComponent, {
        class: mensaje.classes,
        initialState: {
          modal: mensaje,
        },
        backdrop: staticBackdrop ? "static" : true,
      });
      return this.modalConfirmGeneral.content.onClose;
    }
    return of(false);
  }

  public showWarningModal(body: string): Observable<boolean> {
    return this.showModalGeneral({ body, title: ModalTitles.warning, type: ModalType.warning });
  }

  public closeModalConfirm(): any {
    return this.modalConfirmGeneral.content.onClose;
  }

  public showConfirm(mensaje: Modal): Observable<boolean> {
    const modalConfirm = this.modalService.show(ConfirmGeneral, {
      class: mensaje.classes,
      initialState: {
        modal: mensaje,
      },
    });
    return modalConfirm.content.onClose.pipe(first());
  }
}
