import { AuthConfig } from "angular-oauth2-oidc";

import { environment } from "@environments/environment";

const { oidc } = environment;
export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: oidc.issuer,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + oidc.redirectPage,

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: oidc.clientId,

  // set the scope for the permissions the client should request
  // The first TWO are defined by OIDC. The others are customized
  scope: `openid profile offline_access ${oidc.extraScopes}`,

  responseType: "code",
  postLogoutRedirectUri: window.location.origin + oidc.postLogoutUrl,
  showDebugInformation: oidc.showDebugInformation,
  tokenEndpoint: oidc.issuer + "/connect/token",
  customQueryParams: {
    "acr_values": oidc.acrValues
  }
};
