<section class="container">
  <div class="row">
    <app-scroll-arrow id="terminos-uso"></app-scroll-arrow>

    <div class="col-lg-9">
      <h1 uni-heading id="terminos-uso">Términos de Uso</h1>
      <p>Bienvenido al Sitio Web Corporativo de Grupo Universal, S.A. (en adelante “Grupo Universal”).</p>
      <p>
        Esta página establece los Términos (en adelante “Términos”) que regulan el uso de los contenidos informativos y servicios
        y procesos transaccionales ofrecidos disponibles en el Sitio Web de Grupo Universal (en adelante, el “Sitio Web”)
        accesible desde el nombre de dominio <a class="text-info" target="_blank" routerLink="/">www.universal.com.do.</a>
      </p>

      <p>
        Estos Términos contienen los términos, condiciones, y obligaciones de conformidad con las cuales, Usted puede acceder y
        utilizar este Sitio Web y sus contenidos y servicios y procesos transaccionales disponibles en el mismo.
      </p>

      <p>
        Los términos “Usted”, “Ud”, “Visitante” y “Usuario” se emplean aquí para referirse a todos las personas físicas y/o
        jurídicas que por cualquier razón accedan al Sitio.
      </p>

      <p>
        Tanto el acceso como la utilización del Sitio Web y de otros servicios de Internet ofrecidos por Universal están sujetos a
        los siguientes Términos.
      </p>

      <p>Lea atentamente estos Términos (“Términos”).</p>

      <p>
        Al utilizar este Sitio, Usted reconoce que ha leído y comprende estos Términos y declara la aceptación plena y sin
        reservas de los Términos que estén vigentes en el momento de acceso.
      </p>

      <p>
        Usted también reconoce haber leído y comprendido la Políticas de Privacidad y consiente a que Grupo Universal trate sus
        datos personales tal y como allí se establece.
      </p>

      <p>
        Si usted no está de acuerdo con estos Términos o con la Políticas de Privacidad, le rogamos que se abstenga de utilizar
        este Sitio Web y deje de inmediato de navegarlo.
      </p>

      <p>
        La información suministrada, y los servicios y procesos transaccionales disponibles en el Sitio Web tienen como finalidad
        ofrecer información a los Usuarios y/o Visitantes de las actividades, productos y servicios de Grupo Universal.
      </p>

      <p>
        Grupo Universal, titular del Sitio puede revisar y modificar estos Términos en cualquier momento, actualizando esta página
        por lo que debido a que los mismos son vinculantes para Usted, debería visitar esta página cada vez que acceda al Sitio
        Web para revisar los “Términos”.
      </p>

      <p>
        Asimismo, debido a que ciertos servicios y procesos transaccionales, tipo de interacciones y contenidos ofrecidos a los
        Usuarios y/o Visitantes a través del Sitio Web pueden contener normas específicas que reglamentan, complementan y/o
        modifican los presentes Términos, se recomienda a los Usuarios y/o Visitantes tomar conocimiento específico de ellas antes
        de la utilización de dichos contenidos informativos, servicios y procesos transaccionales ofrecidos, así como también
        cualquier tipo de interacción.
      </p>

      <p>
        Los contenidos y la disposición de servicios y procesos transaccionales (incluyendo todo tipo de interacciones en ambiente
        virtual) alojados en este Sitio Web tienen como propósito suministrar información y posibilitar el desarrollo de ciertos
        procesos transaccionales con la finalidad de facilitar el acceso y conocimiento por parte de los Usuarios y Visitantes de
        las actividades, productos y servicios de Grupo Universal.
      </p>

      <p>
        Grupo Universal se reserva el derecho de actualizar, modificar o eliminar la información contenida en el Sitio Web, sin
        previo aviso, teniendo la libertad de limitar o anular el acceso a dicha información, ya sea de forma temporal o
        definitiva.
      </p>

      <p>
        De la misma manera Grupo Universal procederá con la disposición de procesos transaccionales y/o la configuración o
        presentación del Sitio Web y de todas las interacciones dispuestas en el. Así mismo también se reserva del derecho de
        modificar, actualizar y suprimir la manera en que se presenta el diseño del Sitio Web y la forma en que están organizados
        los contenidos y los servicios y procesos transaccionales disponibles y ofrecidos.
      </p>

      <p>
        La información proporcionada por Grupo Universal en su Sitio Web corporativo es meramente informativa, por lo que no
        seremos responsables de los daños o perjuicios que pudieran derivarse de la puesta en práctica de las recomendaciones que
        proporcionamos.
      </p>

      <p>
        Salvo que se establezca expresamente lo contrario, cualquier información, comunicación o material que usted transmita a
        este Sitio Web por medio de correo electrónico u otro canal electrónico alojado en el Sitio Web, incluyendo información,
        preguntas, comentarios, sugerencias será tratado como no confidencial y no propietario y serán propiedad única y exclusiva
        de Grupo Universal, quedando esta última con la facultad de disponer de la misma.
      </p>

      <h3 uni-heading id="acceso-del-sitio-web">1. Acceso del Sitio Web</h3>

      <p>
        El acceso al Sitio Web dado por Ud no exige su previa suscripción o registro. Sin perjuicio de ello, la utilización de
        algunos contenidos informativos disponibles y el acceso a ciertos servicios y procesos transaccionales ofrecidos mediante
        la ejecución de determinados tipos de interacciones que se ofrecen a través del Sitio Web requieren su suscripción o
        registro. Esta suscripción o registro lo convierten en Usuario del Sitio Web de Grupo Universal.
      </p>

      <h3 uni-heading id="utilizacion-del-sitio-web">2. Utilización del Sitio Web</h3>

      <p>
        El Visitante y/o Usuario se compromete a utilizar el Sitio Web de Grupo Universal de conformidad con la ley, de
        conformidad con estos Términos, así como con la moral y buenas costumbres generalmente aceptadas y el orden público, así
        como lo estipulado en la Ley 53-07 contra Crímenes y Delitos de Alta Tecnología.
      </p>

      <p>
        El Visitante y/o Usuario se obliga a abstenerse de utilizar el Sitio con fines o efectos ilícitos, contrarios a lo
        establecido en los Términos, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar,
        inutilizar, sobrecargar o deteriorar el Sitio o impedir la normal utilización del Sitio por parte de los Visitantes y/o
        Usuarios.
      </p>

      <p>
        Queda estrictamente prohibida la utilización de los contenidos y servicios y procesos transaccionales disponibles en el
        Sitio con cualquier propósito o de cualquier manera distinta de la permitida por Grupo Universal en los presentes
        Términos.
      </p>

      <h3
        uni-heading
        id="utilizacion-del-contenido-informativo-y-servicios-y-procesos-transaccionales-disponibles-en-el-sitio-web"
      >
        3. Utilización del contenido informativo y servicios y procesos transaccionales disponibles en el Sitio Web
      </h3>

      <p>
        El uso del Sitio Web, incluyendo el acceso por los Usuarios y la navegación a través del mismo, es libre y gratuito e
        implica el fiel cumplimiento de los presentes Términos.
      </p>

      <p>
        Esta disponible para los Visitantes de Internet la oportunidad de informarse sobre diferente tipos de contenidos
        informativos y se presta el servicio de contactar a Grupo Universal al visitar nuestro Sitio Web mediante un proceso de
        interacci&oacute;n en un ambiente electr&oacute;nico.
      </p>

      <p>
        Para los Usuarios se dispone la posibilidad de acceder a una plataforma privada propiedad de Grupo Universal donde se
        presta el servicio de acceder y ejecutar determinados procesos transaccionales producto de la realizaci&oacute;n de
        determinadas interacciones.
      </p>

      <p>
        A t&iacute;tulo meramente enunciativo y no limitativo se deja constancia que dentro de los servicios y procesos
        transaccionales ofrecidos y disponibles se hayan: (a) reportes con informaci&oacute;n general sobre p&oacute;lizas, (b)
        cotizaciones de productos y servicios, (c) posibilidad de modificaciones de datos de clientes, (d) posibilidades de
        modificaciones de datos acerca de determinadas condiciones de contrataci&oacute;n de productos y servicios, (e) apertura
        de reclamos y solicitudes de modificaci&oacute;n de una p&oacute;liza (f)datos de facturaci&oacute;n, pagos, y cobros, (g)
        acceso a manuales de uso, operaci&oacute;n y gesti&oacute;n, (h) datos sobre comisiones, (i) generaci&oacute;n y acceso de
        reportes estad&iacute;sticos y gr&aacute;ficos y (j) generaci&oacute;n y acceso de reportes con historial de cuentas.
      </p>

      <p>
        Los Usuarios constaran de diferentes perfiles de acceso para interactuar con la plataforma privada transaccional. Dichos
        perfiles ser&aacute;n previamente definidos por Grupo Universal. A t&iacute;tulo meramente enunciativo y no limitativo se
        deja constancia que los perfiles de Usuario definidos son: (a) perfil banca, (b) perfil intermediario, (c) perfil cliente,
        y (d) perfil proveedor.
      </p>

      <p>Los presentes T&eacute;rminos son aplicables a todos los Usuarios y/o Visitantes que utilicen el Sitio.</p>

      <p>
        En caso de que en el futuro se incluyan nuevas secciones en el Sitio que presten servicios y procesos transaccionales
        espec&iacute;ficos, Grupo Universal podr&iacute;a incluir en los mismos T&eacute;rminos espec&iacute;ficos, aplicables a
        dichas secciones bien con prioridad sobre los presentes T&eacute;rminos, o bien complementando los mismos.
      </p>

      <h3 uni-heading id="conducta-del-visitante-y-o-usuario">4. Conducta del Visitante y/o Usuario</h3>

      <p>
        Algunos elementos que forman parte de la informaci&oacute;n ofrecida en el Sitio Web de Grupo Universal Solo est&aacute;n
        disponibles para aquellos Usuarios que se hayan registrado en nuestra base de datos a los cuales Grupo Universal les haya
        asignado y proporcionado un nombre de usuario y contrase&ntilde;a.
      </p>

      <p>Al registrarse en nuestro Sitio Web, Usted declara que tiene al menos 18 a&ntilde;os de edad.</p>

      <p>
        No podr&aacute; facilitar ni utilizar una contrase&ntilde;a, direcci&oacute;n de correo electr&oacute;nico o cualquier
        otro tipo de informaci&oacute;n de cualquier otra persona sin la autorizaci&oacute;n previa del titular, quedando Usted en
        todo momento como responsable de dicho uso.
      </p>

      <p>
        Cuando se registra en nuestra base de datos est&aacute; aceptando proporcionar informaci&oacute;n personal verdadera,
        precisa, actual y completa.
      </p>

      <p>
        Usted es responsable de mantener la confidencialidad de su cuenta y contrase&ntilde;a y de restringir el acceso a su
        dispositivo desde el cual accede a Internet, y acepta asumir la responsabilidad de todas las actividades que se realicen
        utilizando su cuenta, incluyendo la selecci&oacute;n y utilizaci&oacute;n de todos los contenidos, la ejecuci&oacute;n de
        determinados procesos transaccionales productos de la realizaci&oacute;n de determinados tipos de interacciones en la
        plataforma privada de Grupo Universal alojada en el Sitio.
      </p>

      <p>
        Los Visitantes y/o Usuarios Solo podr&aacute;n utilizar los servicios ofrecidos (contenidos, transacciones e
        interacciones) de acuerdo con estos T&eacute;rminos.
      </p>

      <p>
        Grupo Universal se reserva el derecho de denegar el servicio y/o cerrar cuentas, incluyendo, a t&iacute;tulo meramente
        enunciativo y no taxativo, la implementaci&oacute;n de dichas medidas por actividades realizadas por cualquier Usuario y
        que infrinjan estos T&eacute;rminos.
      </p>

      <p>
        Se proh&iacute;be a los Visitantes y/o Usuarios violar o intentar violar la seguridad del Sitio, incluyendo pero no
        limit&aacute;ndose a: (a) acceder de manera il&iacute;cita o permitir el acceso il&iacute;cito de terceros al sistema o a
        datos que no est&eacute;n destinados a tal Usuario o entrar en un servidor o cuenta cuyo acceso no est&aacute; autorizado
        al Usuario, (b) afectar, evaluar o probar la vulnerabilidad de un sistema o red, o violar las medidas de seguridad o
        identificaci&oacute;n sin la adecuada autorizaci&oacute;n, (c) intentar impedir el servicio a cualquier Usuario y/o
        Visitante, anfitri&oacute;n o red, incluyendo sin limitaci&oacute;n, mediante el env&iacute;o de virus al Sitio, o
        mediante saturaci&oacute;n, env&iacute;os masivos (&ldquo;flooding&rdquo;), &ldquo;spamming&rdquo;, bombardeo de correo o
        bloqueos del sistema (&ldquo;crashing&rdquo;), (d) enviar correos no pedidos, incluyendo promociones y/o publicidad de
        productos o servicios, o (e) falsificar cualquier cabecera de paquete TCP/IP o cualquier parte de la informaci&oacute;n de
        la cabecera de cualquier correo electr&oacute;nico, o (f) recoger informaci&oacute;n sobre otros Usuarios, incluyendo
        contrase&ntilde;as, nombres de Usuario, cuentas u otra informaci&oacute;n.
      </p>

      <p>
        Las violaciones de la seguridad del sistema, de la red o de las leyes que regulan la materia pueden comprometer su
        responsabilidad tanto civil como penal.
      </p>

      <p>
        Grupo Universal investigar&aacute; los casos en los que hubieran podido producirse tales violaciones y puede dirigirse a y
        cooperar con la autoridad competente para perseguir a los Usuarios y Visitantes involucrados en tales violaciones, para
        buscar la sanci&oacute;n aplicable al mismo.
      </p>

      <p>
        El Visitante y/o Usuario que incumpla de forma no intencional o culpablemente cualquiera de las precedentes obligaciones
        responder&aacute; de todos los da&ntilde;os y perjuicios que cause.
      </p>

      <h3 uni-heading id="devolucion-o-cancelacion-de-transacciones">5. Devolución o Cancelación de transacciones</h3>

      <p>
        Para cancelar o solicitar la devolución de las transacciones realizadas a través de Universal Xpress debe comunicarse con
        servicio al cliente, disponible las 24 horas del día, los 7 días de la semana, desde Santo Domingo, 809-544-7111,
        Santiago: 809-580-3252 La Romana: 809-813-7655, Puerto Plata: 809-589-1122, otras localidades del interior sin cargos:
        1-809-200-1283 y desde el exterior sin cargo: 1-877-239-5430.
      </p>

      <h3 uni-heading id="uso-de-nombres-de-usuarios-y-contraseñas">6. Uso de nombres de Usuarios y contraseñas</h3>

      <p>El acceso y uso de ciertas secciones del Sitio Web requieren de un nombre de Usuario y una contrase&ntilde;a.</p>

      <p>
        Como parte del proceso de acceso a la plataforma transaccional y de ejecuci&oacute;n de reportes, debe ingresar un nombre
        de Usuario y una contrase&ntilde;a, y facilitar a Grupo Universal informaci&oacute;n exacta, completa y actualizada.
      </p>

      <p>
        Cualquier persona que conozca su nombre de usuario y contrase&ntilde;a puede acceder a la plataforma privada del Sitio Web
        y a la informaci&oacute;n all&iacute; disponible para Usted. En consecuencia, debe mantener en secreto estos datos.
      </p>

      <p>
        Al aceptar estos T&eacute;rminos, Usted consiente ser el &uacute;nico responsable de la confidencialidad y uso de su
        respectivo nombre de Usuario y contrase&ntilde;a, as&iacute; como de cualquier comunicaci&oacute;n hecha a trav&eacute;s
        del Internet utilizando su nombre de Usuario y contrase&ntilde;a.
      </p>

      <p>
        Usted notificar&aacute; inmediatamente a Grupo Universal cualquier p&eacute;rdida o robo de su contrase&ntilde;a o
        cualquier uso no autorizado de &eacute;sta.
      </p>

      <p>
        Grupo Universal se reserva el derecho a eliminar o cambiar una contrase&ntilde;a en cualquier momento y por cualquier
        motivo.
      </p>

      <h3 uni-heading id="uso-del-correo-electronico-y-de-la-aplicacion-de-live-chat">
        7. Uso del correo electrónico y de la aplicación de Live Chat
      </h3>

      <p>
        Usted puede utilizar el correo electr&oacute;nico para ponerse en contacto con Grupo Universal, para los fines
        se&ntilde;alados en los presentes T&eacute;rminos y en la Pol&iacute;ticas de Privacidad.
      </p>

      <p>
        A pesar de que es deseo de Grupo Universal atender los correos electr&oacute;nicos recibidos con prontitud, Grupo
        Universal no garantiza la respuesta efectiva los mismos, ya que depender&aacute; del volumen de correspondencia recibida y
        de la complejidad de las cuestiones planteadas.
      </p>

      <p>
        Universal no garantiza tampoco el funcionamiento del correo electr&oacute;nico, tanto en la recepci&oacute;n como en el
        env&iacute;o, por estar fuera de su control.
      </p>

      <p>
        Toda la informaci&oacute;n recibida por esta v&iacute;a por parte de los Usuarios y/o Visitantes del Sitio Web de Grupo
        Universal ser&aacute; tratada conforme a lo establecido en la Pol&iacute;ticas de Privacidad.
      </p>

      <p>
        Le rogamos se abstenga de enviar informaci&oacute;n confidencial, privilegiada o cualquier otra informaci&oacute;n
        reservada por v&iacute;a del Live Chat.
      </p>

      <h3 uni-heading id="sugerencias">8. Sugerencias</h3>

      <p>
        Grupo Universal agradece su opini&oacute;n y sugerencias sobre c&oacute;mo mejorar su Sitio Web y la experiencia de un
        Visitante y/o Usuario en el.
      </p>

      <p>
        No obstante, se considerar&aacute; que cualquier idea, sugerencia, informaci&oacute;n, know-how, material u otro contenido
        (en adelante conjuntamente, &ldquo;Sugerencias&rdquo;) recibidos a trav&eacute;s del Sitio conlleva la concesi&oacute;n a
        Grupo Universal de un derecho y licencia no exclusivos, gratuitos, perpetuos e irrevocables para adoptar, publicar,
        reproducir, transmitir, difundir, distribuir, copiar, utilizar, crear obras derivadas, exhibir, en el territorio mundial
        (en todo o parte), las Sugerencias, o actuar sobre la base de tales Sugerencias sin necesidad de aprobaci&oacute;n o
        precio adicionales, en cualquier forma, medio o tecnolog&iacute;a actualmente conocidos o por desarrollar, durante la
        vigencia de los derechos que puedan existir sobre dichas Sugerencias.
      </p>

      <p>Usted renuncia por los presentes T&eacute;rminos a reclamar en contra de lo establecido en este apartado.</p>

      <h3 uni-heading id="contenidos-de-terceros">9. Contenidos de terceros</h3>

      <p>
        El Sitio Web puede contener enlaces a otros sitios de Internet y a recursos de terceros que son completamente
        independientes a este Sitio. Se hace esto para facilitarte la localizaci&oacute;n de informaci&oacute;n y complementar los
        servicios ofrecidos por Grupo Universal en su Sitio.
      </p>

      <p>
        Grupo Universal no se responsabiliza ni garantiza, en modo alguno, la exactitud, insuficiencia o autenticidad de la
        informaci&oacute;n suministrada por cualquier persona o entidad, f&iacute;sica o jur&iacute;dica, con o sin personalidad
        jur&iacute;dica propia, a trav&eacute;s de dichos enlaces de hipertexto.
      </p>

      <p>
        Grupo Universal no garantiza que los enlaces a otros lugares sean exactos en el momento de su acceso. Estos enlaces se
        proporcionan Solo para su conveniencia.
      </p>

      <p>
        Las eventuales referencias que se hagan en este Sitio a cualquier producto, servicio, proceso, enlace, hipertexto o
        cualquier otra informaci&oacute;n que sean de titularidad de terceros, no constituye o implican respaldo, patrocinio o
        recomendaci&oacute;n por parte de Grupo Universal.
      </p>

      <p>
        Usted es el &uacute;nico responsable de comprender cualquier t&eacute;rmino y condici&oacute;n que pudieran aplicarse
        durante su visita en el sitio de un tercero.
      </p>

      <p>
        El establecimiento del hiperv&iacute;nculo no implica en ning&uacute;n caso la existencia de relaci&oacute;n comercial
        alguna entre Grupo Universal y el titular de la p&aacute;gina web en la que se establezca el mismo.
      </p>

      <p>
        No se establecer&aacute;n hiperv&iacute;nculos al Sitio en p&aacute;ginas web que incluyan informaci&oacute;n o contenidos
        il&iacute;citos, inmorales o contrarios a las buenas costumbres, al orden p&uacute;blico, a los usos aceptados en Internet
        o que de cualquier forma contravengan derechos de terceros.
      </p>

      <h3 uni-heading id="modificaciones-en-el-sitio-web-de-grupo-universal">
        10. Modificaciones en el Sitio Web de Grupo Universal
      </h3>

      <p>
        Nos reservamos el derecho de ocasionalmente modificar o interrumpir, de manera temporal o permanente, la
        informaci&oacute;n y servicios y procesos transaccionales disponibles por Grupo Universal en el Sitio Web corporativo con
        o sin notificaci&oacute;n previa.
      </p>

      <p>
        Usted est&aacute; de acuerdo en que Grupo Universal no ser&aacute; responsable frente a Usted o frente a cualquier tercero
        por cualquier modificaci&oacute;n, suspensi&oacute;n o interrupci&oacute;n de la informaci&oacute;n y servicios y procesos
        transaccionales disponibles, incluyendo la posibilidad de generar transacciones como producto de la ejecuci&oacute;n de
        determinado tipo de interacciones.
      </p>

      <h3 uni-heading id="fallos">11. Fallos</h3>
      <p>
        Grupo Universal no se responsabiliza de los posibles da&ntilde;os o perjuicios que se pudieran derivar de interferencias,
        omisiones, interrupciones, virus inform&aacute;ticos, aver&iacute;as telef&oacute;nicas o desconexiones en el
        funcionamiento operativo del sistema seleccionado por el Usuario y/o Visitante motivados por causas ajenas; de retrasos o
        bloqueos en el uso o de cualquier otra causa que impida el correcto suministro de servicio del sistema elegido causado por
        deficiencias o sobrecargas de l&iacute;neas telef&oacute;nicas o sobrecargas en el centro de proceso de datos de Grupo
        Universal, en los servidores de Internet o en otros sistemas electr&oacute;nicos, as&iacute; como de da&ntilde;os que
        puedan ser causados por terceras personas mediante intromisiones ileg&iacute;timas en el sistema elegido, fuera del
        control de Grupo Universal.
      </p>

      <p>
        Grupo Universal no se hace responsable de los posibles errores que se pudieran producir por el hecho de utilizar versiones
        de navegadores no actualizadas, o de las consecuencias que se pudieran derivar del mal funcionamiento o incorrecta
        utilizaci&oacute;n del navegador.
      </p>

      <p>
        Grupo Universal no garantiza que ninguna informaci&oacute;n, software u otro material accesible desde el Sitio Web
        accesible desde el dominio <a class="text-info" target="_blank" routerLink="/">www.universal.com.do</a> est&eacute; libre
        de virus.
      </p>

      <p>
        Usted expresamente exime y libera a Grupo Universal y a sus proveedores de cualquier reclamaci&oacute;n por da&ntilde;os
        derivados de una causa que est&eacute; fuera de su control, incluyendo -a efectos enunciativos y no limitativos: (a)
        fallos en los equipamientos electr&oacute;nicos o mec&aacute;nicos, (b) fallos en las l&iacute;neas de
        comunicaci&oacute;n, telef&oacute;nicas u otros problemas de interconexi&oacute;n, (c) infecci&oacute;n con virus
        inform&aacute;ticos, (d) accesos no autorizados, (e) robos, (f) errores de los operadores, (g) problemas
        climatol&oacute;gicos, terremotos u otras cat&aacute;strofes naturales, (h) huelgas u otros conflictos laborales, (i)
        guerras, terrorismo y (j) restricciones gubernamentales.
      </p>

      <h3 uni-heading id="servidor-seguro">12. Servidor seguro</h3>

      <p>
        Los formularios de contenidos alojados en el Sitio Web est&aacute;n configurados para que el Usuario y/o Visitante puedan
        enviar informaci&oacute;n con total seguridad y confianza. Todos los datos enviados a trav&eacute;s de nuestros
        formularios ser&aacute;n encriptados a trav&eacute;s de nuestro servidor seguro para proteger su confidencialidad.
      </p>

      <h3 uni-heading id="discrepancias-de-documentos-en-version-electronica">
        13. Discrepancias de documentos en versión electrónica
      </h3>

      <p>
        Grupo Universal no se responsabiliza de las posibles discrepancias que puedan surgir entre la versi&oacute;n de cualquier
        documento impreso y la versi&oacute;n electr&oacute;nica de los mismos publicados en su Sitio Web.
      </p>

      <h3 uni-heading id="propiedad-intelectual">14. Propiedad intelectual</h3>

      <p>
        Todos los contenidos, aplicaciones y signos distintivos incluidos; tales como textos, gr&aacute;ficos, logotipos, iconos
        de botones, marcas, sonidos, videos, nombres, nombres de dominio, secuencias de c&oacute;digos autoejecutables,
        im&aacute;genes, como as&iacute; tambi&eacute;n la compilaci&oacute;n de los mismos, y todos los programas de ordenador
        utilizados en este Sitio son propiedad de Universal, o de sus proveedores o compa&ntilde;&iacute;as vinculadas y
        est&aacute;n protegidos por las leyes de propiedad intelectual de Republica Dominicana y por los correspondientes acuerdos
        internacionales; o en su caso, Grupo Universal cuenta con licencia de uso, y gozan, en consecuencia, de la
        protecci&oacute;n propia de los derechos sobre la propiedad intelectual e industrial.
      </p>

      <p>
        Usted acepta respetar todos los avisos y/o derechos de autor u otras restricciones contenidas en este Sitio Web y no los
        modificar&aacute; de ning&uacute;n modo.
      </p>

      <p>
        Salvo que se permita expl&iacute;citamente, de conformidad con las leyes de propiedad intelectual, Usted no podr&aacute;
        modificar, aplicar t&eacute;cnicas de ingenier&iacute;a inversa, publicar, transmitir, visualizar, participar en la
        transferencia o venta o crear trabajos derivados, ni de cualquier otra forma explotar comercialmente o facilitar a un
        tercero el contenido (informaci&oacute;n como tal, reportes producto de transacciones y formularios de datos) del Sitio
        Web o de las publicaciones sin la autorizaci&oacute;n expresa por escrito de Grupo Universal.
      </p>

      <p>
        No le concedemos ninguna licencia, expresa o t&aacute;cita, de propiedad intelectual de Grupo Universal, excepto que estos
        T&eacute;rminos as&iacute; lo autoricen expresamente.
      </p>

      <p>
        Usted no copiar&aacute; ni adaptar&aacute; el c&oacute;digo HTML que Grupo Universal ha creado para generar sus
        p&aacute;ginas web que componen el Sitio Web. Dicho c&oacute;digo se haya protegido tambi&eacute;n por los derechos de
        propiedad intelectual de Grupo Universal.
      </p>

      <p>
        Cualquier otro nombre, logotipo o icono que identifique a Grupo Universal o a sus productos o servicios son marcas
        propiedad de Grupo Universal
      </p>

      <p>
        Los nombres y marcas de productos de terceros y los nombres y marcas de terceras empresas mencionadas o publicadas en el
        Sitio Web son nombres y marcas de sus respectivos propietarios.
      </p>

      <h3 uni-heading id="asuncion-de-riesgos">15. Asunción de riesgos</h3>

      <p>
        Usted utiliza Internet asumiendo su propio riesgo y con sujeci&oacute;n a toda la legislaci&oacute;n o normativa local,
        nacional o internacional aplicable.
      </p>

      <p>
        Sin perjuicio de que Grupo Universal ha procurado crear un Sitio Web seguro y fiable, la confidencialidad de cualquier
        comunicaci&oacute;n o material transmitido a/del Sitio en Internet no puede ser garantizada.
      </p>

      <p>
        En consecuencia, Grupo Universal y sus proveedores no ser&aacute;n responsables de la seguridad de la informaci&oacute;n
        transmitida v&iacute;a Internet, de la exactitud de la informaci&oacute;n contenida en el Sitio, o de las consecuencias de
        confiar en dicha informaci&oacute;n.
      </p>

      <p>
        Universal y sus proveedores no tendr&aacute;n ninguna responsabilidad por interrupciones o falta de funcionamiento de
        Internet, de la red o de servicios de alojamiento. Usted asume en exclusiva todo el riesgo que pueda derivar de su
        utilizaci&oacute;n de la Web.
      </p>

      <h3 uni-heading id="exclusion-de-responsabilidad-y-garantias">16. Exclusión de responsabilidad y garantías</h3>

      <p>
        La informaci&oacute;n y los servicios y procesos transaccionales disponibles y ofrecidos por Grupo Universal a
        trav&eacute;s de su Sitio y todos los contenidos se brindan en t&eacute;rminos de &ldquo;como est&eacute;n&rdquo; y
        &ldquo;como est&eacute;n disponibles&rdquo;.
      </p>

      <p>Universal expresamente excluye toda garant&iacute;a de cualquier tipo, ya sea expresa o t&aacute;cita.</p>

      <p>
        Universal no otorga ninguna garant&iacute;a de que la calidad de cualquier contenido y/o servicio y proceso transaccional
        ofrecido, u otro material obtenido por Usted a trav&eacute;s del Sitio satisfagan las necesidades y expectativas de los
        Visitantes y/o Usuarios, ni que la informaci&oacute;n y/o servicios y procesos transaccionales ofrecidos ser&aacute;n
        ininterrumpidos, oportunos, seguros o estar&aacute;n libre de errores, ni que los resultados que puedan obtenerse de la
        utilizaci&oacute;n de la informaci&oacute;n y servicios y procesos transaccionales disponibles y ofrecidos ser&aacute;n
        precisos o confiables.
      </p>

      <p>
        Universal no se responsabiliza del mal uso que se realice de los contenidos y servicios y procesos transaccionales
        ofrecidos en el Sitio, ni de los da&ntilde;os o perjuicios que se deriven del acceso o uso indebido de la
        informaci&oacute;n y los resultados de las interacciones de dicho Sitio Web; tanto al navegar en su dominio
        p&uacute;blico, como en su plataforma transaccional privada.
      </p>

      <p>
        Usted es consciente y acepta que ninguna informaci&oacute;n o consejo, ya sea oral o escrito, facilitado por Grupo
        Universal o por cualquiera de sus empleados o relacionados en relaci&oacute;n con el Sitio constituye una
        declaraci&oacute;n o una garant&iacute;a a menos que dicha informaci&oacute;n o consejo est&eacute; cubierto por estos
        T&eacute;rminos.
      </p>

      <p>
        Universal y sus proveedores y relacionados no ser&aacute;n bajo ninguna circunstancia responsables de ning&uacute;n
        da&ntilde;o especial, directo, indirecto, incidental o consecuencial de cualquier tipo, incluyendo (a modo enunciativo y
        no limitativo) compensaciones, reembolsos o da&ntilde;os por las p&eacute;rdidas o gastos ocasionados, deterioro de la
        reputaci&oacute;n comercial, perdida de informaci&oacute;n y costos financieros.
      </p>

      <p>
        En la medida de lo que lo que permita la ley, Grupo Universal y sus proveedores y relacionados se excluyen de cualquier
        responsabilidad por cualquier da&ntilde;o directo derivado del uso de su Sitio Web, sus contenidos y servicios y procesos
        transaccionales ofrecidos.
      </p>

      <h3 uni-heading id="limite-de-responsabilidad">17. Límite de responsabilidad</h3>

      <p>
        En ning&uacute;n caso Grupo Universal, de conformidad con cualquier fundamento legal, ser&aacute; responsable por
        ning&uacute;n da&ntilde;o directo, indirecto, emergente, punitivo o consecuencial.
      </p>

      <p>
        Grupo Universal no se responsabiliza por los da&ntilde;os y perjuicios de toda naturaleza que puedan deberse a la falta de
        exactitud, exhaustividad, actualidad, as&iacute; como a errores u omisiones de los que pudieran adolecer los contenidos
        informativos y servicios y procesos transaccionales disponibles y ofrecidos de este Sitio Web u otros contenidos y
        procesos transaccionales e interacciones que deriven en procesos transaccionales a los que se pueda acceder a
        trav&eacute;s del mismo.
      </p>

      <p>
        Grupo Universal no asume ning&uacute;n deber o compromiso de verificar, ni de vigilar sus contenidos (procesos
        transaccionales e interacciones) e informaciones.
      </p>

      <p>
        Asimismo, Grupo Universal no garantiza la disponibilidad, continuidad ni la infalibilidad del funcionamiento del Sitio
        Web, y en consecuencia excluye, en la m&aacute;xima medida permitida por la legislaci&oacute;n vigente, cualquier
        responsabilidad por los da&ntilde;os y perjuicios de toda naturaleza que puedan deberse a la falta de disponibilidad o de
        continuidad del funcionamiento del Sitio Web y de los servicios y procesos transaccionales habilitados en el mismo,
        as&iacute; como a los errores en el acceso a las distintas p&aacute;ginas web desde las que, en su caso se presten dichos
        servicios.
      </p>

      <p>
        Aun cuando Grupo Universal realiza razonables esfuerzos para incluir informaci&oacute;n precisa y actualizada en este
        Sitio Web, los errores y omisiones pueden ocurrir algunas veces. Grupo Universal no garantiza ni avala la precisi&oacute;n
        del contenido de este sitio.
      </p>

      <p>
        Grupo Universal tampoco asume alguna responsabilidad por alg&uacute;n da&ntilde;o o virus que pueda infectar su
        dispositivo desde donde accede a Internet u otra propiedad como resultado de su acceso, uso o navegaci&oacute;n en el
        Sitio Web o su descarga de alg&uacute;n material, informaci&oacute;n, texto, im&aacute;genes, video o audio de este Sitio
        Web.
      </p>

      <p>
        Grupo Universal no garantiza ni avala que su uso de materiales exhibidos en este sitio no infrinja derechos de terceras
        partes que no pertenezcan o est&eacute;n afiliadas a Universal.
      </p>

      <h3 uni-heading id="exoneracion-de-responsabilidad">18. Exoneración de responsabilidad</h3>

      <p>
        La informaci&oacute;n respecto a los productos y servicios que ofrece Grupo Universal en el Sitio Web corporativo
        accesible desde el nombre de dominio <a class="text-info" target="_blank" routerLink="/">www.universal.com.do </a>no
        refleja todos los t&eacute;rminos y condiciones aplicables a cada uno de los productos y/o servicios comercializados por
        Universal.
      </p>

      <p>
        La informaci&oacute;n provista en el Sitio Solo tiene un car&aacute;cter indicativo, orientativo y estimativo. Grupo
        Universal no garantiza la total actualizaci&oacute;n y/o exactitud y/o disponibilidad en todo momento de los contenidos y
        servicios y procesos transaccionales disponibles y ofrecidos en su Sitio Web, si bien hace todo lo posible para que
        as&iacute; sea.
      </p>

      <p>
        Igualmente Grupo Universal no garantiza la utilidad de su Sitio o de sus servicios y procesos transaccionales disponibles
        y ofrecidos para ninguna actividad en particular por lo que el acceso al Sitio y el uso de sus contenidos se realizan bajo
        la responsabilidad del Usuario y/o Visitante.
      </p>

      <p>
        Grupo Universal no garantiza, ni avala el uso o los resultados del uso de los materiales en el Sitio Web en
        t&eacute;rminos de su exactitud, precisi&oacute;n, confiabilidad u otros.
      </p>

      <p>
        Se exonera a Grupo Universal de responsabilidad ante cualquier da&ntilde;o o perjuicio que pudiera sufrir el Usuario y/o
        Visitante como consecuencia de errores, defectos u omisiones en la informaci&oacute;n facilitada o de la imposibilidad de
        efectuar adecuadamente procesos transaccionales o en el caso de que producto de las interacciones dirigidas a consolidar
        transacciones se obtengan informaci&oacute;n inconsistente y/o err&oacute;nea.
      </p>

      <p>
        Usted acepta defender, exonerar y liberar de toda responsabilidad a Universal, sus licenciantes, proveedores y cualquier
        tercero proveedor de contenidos a la informaci&oacute;n y servicios y procesos transaccionales ofrecidos y sus respectivos
        consejeros, directivos, empleados y relacionados, por cualquier demanda, p&eacute;rdida, gasto, da&ntilde;os y costos,
        incluyendo los honorarios razonables de abogados, que deriven de la utilizaci&oacute;n de la informaci&oacute;n y los
        servicios y procesos transaccionales ofrecidos por Grupo Universal en el Sitio y que resulten de la violaci&oacute;n de
        estos T&eacute;rminos o de cualquier actividad relacionada con su cuenta (incluyendo conducta negligente o il&iacute;cita)
        por su parte o por la parte de cualquier Usuario y/o Visitante que acceda a nuestro Sitio por medio de su cuenta.
      </p>

      <h3 uni-heading id="indemnizacion">19. Indemnización</h3>

      <p>
        Usted acepta indemnizar y mantener libre e indemne a Universal y sus relacionados, todas sus filiales, y cualquiera de sus
        directivos, administradores, empleados, accionistas, representantes legales, relacionados, sucesores y cesionarios por
        cualquier da&ntilde;o, responsabilidad, costos y gastos (incluyendo honorarios razonables de abogados y profesionales y
        costas de litigios) que sean consecuencia de reclamaciones de terceros basadas o relativas al uso de su Sitio Web o a una
        infracci&oacute;n de estos T&eacute;rminos cometida por Usted.
      </p>

      <h3 uni-heading id="control">20. Control</h3>

      <p>
        Grupo Universal tiene el derecho, pero no la obligaci&oacute;n, de controlar los servicios y procesos transaccionales
        disponibles y ofrecidos para determinar el cumplimiento de estos T&eacute;rminos y de cualquier otra norma de
        funcionamiento establecida por Grupo Universal, as&iacute; como para cumplir con cualquier ley, reglamento o requerimiento
        legal aplicable.
      </p>

      <p>
        Sin limitar lo anterior, tendremos el derecho de eliminar cualquier contenido y/o servicio y proceso transaccional que,
        discrecionalmente, creamos que viole las disposiciones de nuestros T&eacute;rminos.
      </p>

      <h3 uni-heading ID="resolucion">21. Resolución</h3>

      <p>
        Grupo Universal puede, discrecionalmente y sin responsabilidad alguna, cancelar su nombre de Usuario y contrase&ntilde;a,
        impedir la utilizaci&oacute;n de la informaci&oacute;n y servicios y procesos transaccionales disponibles y ofrecidos por
        cualquier motivo, incluyendo, a t&iacute;tulo meramente enunciativo y no limitativo, el caso de que Grupo Universal
        considere que Usted ha violado o ha actuado en contra de la letra o del esp&iacute;ritu de estos T&eacute;rminos.
      </p>

      <p>
        Cualquier cancelaci&oacute;n del acceso a la informaci&oacute;n y servicios y procesos transaccionales ofrecidos, de
        conformidad con cualquier disposici&oacute;n de estos T&eacute;rminos, puede efectuarse sin notificaci&oacute;n previa.
      </p>

      <p>
        Grupo Universal puede desactivar o eliminar inmediatamente su cuenta y toda la informaci&oacute;n relacionada y/o prohibir
        cualquier acceso a la informaci&oacute;n y/o servicios y procesos transaccionales ofrecidos en el futuro.
      </p>

      <p>
        Grupo Universal se reserva el derecho de denegarle acceso a su Sitio Web o a parte del mismo en cualquier momento y por
        cualquier motivo.
      </p>

      <h3 uni-heading id="vigencia-y-notificación-de-cambios">22. Vigencia y notificación de cambios</h3>

      <p>
        Estos T&eacute;rminos ser&aacute;n efectivos desde el primer momento en que ingrese al Sitio Web. En el futuro, Grupo
        Universal puede revisar o modificar estos T&eacute;rminos para mantenerlos al d&iacute;a en relaci&oacute;n a la
        informaci&oacute;n y servicios y procesos transaccionales disponibles y ofrecidos en el Sitio. Visite la p&aacute;gina web
        T&eacute;rminos peri&oacute;dicamente para informarse acerca de cualquier cambio.
      </p>

      <p>
        La fecha de actualizaci&oacute;n se utiliza para alertarlo acerca de las modificaciones recientes. Su acceso o
        utilizaci&oacute;n de la informaci&oacute;n y servicios y procesos transaccionales disponibles y ofrecidos por Grupo
        Universal posteriores a dicha actualizaci&oacute;n se entender&aacute; como su consentimiento para estar sujeto a dichos
        cambios.
      </p>

      <h3 uni-heading id="conocimiento-y-consentimiento">23. Conocimiento y consentimiento</h3>

      <p>
        Al utilizar el Sitio Web de Grupo Universal, usted declara que ha le&iacute;do y comprendido estos T&eacute;rminos, y que
        acepta estar vinculado por sus condiciones. Usted tambi&eacute;n reconoce haber le&iacute;do y comprendido la
        Pol&iacute;ticas de Privacidad y autoriza a Universal a la utilizaci&oacute;n de sus datos personales.
      </p>

      <p>
        Si desea contactar con Grupo Universal en relaci&oacute;n a estos T&eacute;rminos o la Pol&iacute;ticas de Privacidad, por
        favor, contacte con:
      </p>

      <address>
        <strong>Grupo Universal</strong> <br />
        Av. Winston Churchill No. 1100, Ensanche Evaristo Morales <br />
        Santo Domingo, D.N., República Dominicana <br />
        <strong>Tel. </strong>809 544 7111 <br />
        <strong>Fax: </strong>809 544 7161 <br />
        <strong>E-mail: </strong>
        <a class="text-info" target="_blank" href="mailto:canales-e@universal.com.do">canales-e@universal.com.do</a> <br />
        <strong>Sitio Web: </strong
        ><a class="text-info" target="_blank" rel="noopener noreferrer" href="https:// www.universal.com.do">
          www.universal.com.do</a
        >
      </address>

      <h3 uni-heading id="renuncia">24. Renuncia</h3>

      <p>
        El hecho de no reclamar el cumplimiento estricto de cualquiera de las condiciones de estos T&eacute;rminos no se
        considerar&aacute; como una renuncia de derechos frente a infracciones o incumplimientos posteriores de las mismas. La
        falta de ejercicio por Grupo Universal de un derecho derivado de los T&eacute;rminos no supone una renuncia al ejercicio
        de cualquier otro derecho o del mismo derecho en un momento posterior.
      </p>

      <h3 uni-heading id="totalidad-del-acuerdo-y-reserva-de-derechos">25. Totalidad del acuerdo y reserva de derechos</h3>

      <p>
        Sus derechos a utilizar determinados materiales disponibles en o a trav&eacute;s del Sitio Web corporativo de Grupo
        Universal pueden estar sujetos a diferentes acuerdos escritos suscritos con Grupo Universal (en adelante &ldquo;Otros
        Acuerdos&rdquo;).
      </p>

      <p>
        Determinadas p&aacute;ginas o elementos del Sitio, con contenido y servicios y procesos transaccionales disponibles y
        ofrecidos por Grupo Universal, pueden tener condiciones diferentes o adicionales (en adelante &ldquo;Condiciones
        Particulares&rdquo;), que le ser&aacute;n indicadas cuando acceda a dichas p&aacute;ginas o elementos, y se
        considerar&aacute; que usted ha aceptado las Condiciones Particulares si accede a las p&aacute;ginas o elementos y las
        utiliza. En caso de conflicto entre estos T&eacute;rminos y las Condiciones Particulares, las Condiciones Particulares
        prevalecer&aacute;n en cuanto a su aplicaci&oacute;n a dichas p&aacute;ginas o elementos. En caso de conflicto entre estos
        T&eacute;rminos y uno o varios Otros Acuerdos, las condiciones de dichos Otros Acuerdos ser&aacute;n de aplicaci&oacute;n.
      </p>

      <p>
        Con la excepci&oacute;n de cualesquiera Condiciones Particulares u Otros Acuerdos, estos T&eacute;rminos representan,
        junto con la Pol&iacute;ticas de Privacidad, la totalidad del acuerdo suscrito entre Usted y Grupo Universal en
        relaci&oacute;n con su utilizaci&oacute;n del material disponible en o a trav&eacute;s del Sitio Web corporativo, y deja
        sin efecto toda comunicaci&oacute;n o propuesta anterior o coet&aacute;nea, ya fueran electr&oacute;nicas, orales o
        escritas, entre Usted y Grupo Universal relativas al Sitio.
      </p>

      <p>Cualquier derecho que no haya sido expresamente concedido por los presentes T&eacute;rminos queda reservado.</p>

      <h3 uni-heading id="legislacion-aplicable">26. Legislación aplicable</h3>

      <p>
        Estos T&eacute;rminos de Uso se rigen por las leyes de la Rep&uacute;blica Dominicana, muy particularmente, de la Ley No.
        53-07 de fecha 10 de abril de 2007 sobre Delitos y Cr&iacute;menes de Alta Tecnolog&iacute;a.
      </p>

      <p>
        Cualesquiera demanda derivada de estos T&eacute;rminos se someter&aacute; exclusivamente a la jurisdicci&oacute;n de los
        juzgados y tribunales de la ciudad de Santo Domingo, Rep&uacute;blica Dominicana.
      </p>
    </div>

    <div class="col-lg-3">
      <app-secciones-list [sections]="sections"></app-secciones-list>
    </div>
  </div>
</section>
