import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeEsDo from "@angular/common/locales/es-DO";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UrlSerializer } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
//
import {
  UniButtonsModule,
  UniHeadingModule,
  UniIconsModule,
  UniFormFieldModule,
  UniInputModule,
  UniListModule,
} from "@universal-ui";
import { AuthConfig, OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
//NGX-BOOTSTRAP
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ToastrModule } from "ngx-toastr";
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, POSITION, SPINNER } from "ngx-ui-loader";
import { UiSwitchModule } from "ngx-ui-switch";
import { environment } from "src/environments/environment";

import { storageFactory } from "@shared/Factory/storageFactory";
import { FeatureFlagGuard } from "@shared/Guards/feature-flag-guard";
import { UserAuthenticatedGuard } from "@shared/Guards/user-authenticated.guard";
import { UserHasPermissionGuard } from "@shared/Guards/user-has-permission.guard";
import { UserPermissionGuard } from "@shared/Guards/user-permissions.guard";
import { ResponseInterceptor } from "@shared/Interceptors/response.interceptor";
import { SharedModule } from "@shared/Modules/shared.module";
import { appInsight } from "@shared/Services/appInsight.service";
import { ErrorHandlerService } from "@shared/Services/error-handler.service";
import { FeatureFlagService } from "@shared/Services/featureFlag.service";
import { LowerCaseUrlSerializer } from "@shared/Validators/lower.case.url.serializer";

import { AFIModule } from "./AFI/afi.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AvisoLegalModule } from "./AvisoLegal/aviso-legal.module";
import { GenericErrorComponent } from "./ErrorPages/GenericError/generic-error.component";
import { NotFoundComponent } from "./ErrorPages/NotFound/notfound.component";
import { SessionExpiredComponent } from "./ErrorPages/SessionExpired/session-expired.component";
import { UnathorizedComponent } from "./ErrorPages/Unathorized/unathorized.component";
import { FooterComponent } from "./Footer/footer.component";
import { IntermediaryIdeaToastComponent } from "./General/IntermediaryIdea/Components/IntermediaryIdeaToast/intermediary-idea-toast.component";
import { InicioHeaderModule } from "./InicioHeader/inicio-header.module";
import { authConfig } from "./oauth-configuration";
import { RedirectLoginComponent } from "./RedirectLogin/redirect-login.component";
import { ResetPasswordComponent } from "./ResetPassword/reset-password.component";

registerLocaleData(localeEsDo);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#012169",
  bgsType: SPINNER.fadingCircle, // background spinner type
  bgsPosition: POSITION.bottomRight,
  fgsColor: "#00C1D5",
  fgsType: SPINNER.fadingCircle, // foreground spinner type
  fgsPosition: POSITION.centerCenter,
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NotFoundComponent,
    RedirectLoginComponent,
    GenericErrorComponent,
    UnathorizedComponent,
    ResetPasswordComponent,
    SessionExpiredComponent,
    IntermediaryIdeaToastComponent,
  ],
  imports: [
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          environment.enlineaBackendUrl,
          environment.afi.adobeSignUrl,
          environment.salud.appClientes,
          environment.apiReclamaciones,
          environment.apiReclamacionesGenerales,
          environment.apiReclamacionesClientes,
          environment.apiReclamacionesClientes,
          environment.refundAttachmentsBackendUrl,
        ],
        sendAccessToken: true,
      },
    }),
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      excludeRegexp: ["spinner=false", "/?spinner=false/"],
      showForeground: true,
      exclude: ["https://idp-qa.azurewebsites.net/", "https://idp-des.azurewebsites.net/", "https://idp.universal.com.do/"],
    }),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    SharedModule,
    UiSwitchModule.forRoot({}),
    InicioHeaderModule,
    AvisoLegalModule,
    AFIModule,
    UniHeadingModule,
    UniButtonsModule,
    UniIconsModule,
    UniFormFieldModule,
    UniInputModule,
    UniListModule,
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    appInsight,
    FeatureFlagService,
    // RedirectLoginService,
    UserPermissionGuard,
    UserAuthenticatedGuard,
    UserHasPermissionGuard,
    FeatureFlagGuard,
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: AuthConfig, useValue: authConfig },
    { provide: LOCALE_ID, useValue: "es-DO" },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
