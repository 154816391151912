import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, ReplaySubject } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "@environments/environment";

import { Prestador, Usuario } from "../Entities/Usuario/usuario.entity";
import { UserProduct } from "../Interfaces/user-products.interface";

const { enlineaBackendUrl, refundAttachmentsBackendUrl } = environment;

@Injectable({
  providedIn: "root",
})
export class UsuariosRepository {
  private informacionUsuario: ReplaySubject<Usuario>;
  public isUserLoaded: boolean = false;

  constructor(private http: HttpClient) {}

  //GET

  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public getAllUsersFilter<
    T extends {
      nombres?: string;
      apellidos?: string;
      desde?: any;
      hasta?: any;
      identificacion?: string;
      activo?: boolean;
      perfil?: any;
      rol?: any;
    }
  >(
    pageSize: number = 10,
    pageNumber: number = 1,
    maxPages: number = 10,
    filterOptions?: T,
    spinner: boolean = true
  ): Observable<any> {
    if (filterOptions && Object.keys(filterOptions).length > 0)
      filterOptions = {
        ...filterOptions,
        desde: filterOptions.desde ? (filterOptions.desde as Date).toISOString() : undefined,
        hasta: filterOptions.hasta ? (filterOptions.hasta as Date).toISOString() : undefined,
        perfil: filterOptions.perfil ? filterOptions.perfil.id : undefined,
        rol: filterOptions.rol ? filterOptions.rol.id : undefined,
      };

    let url = `${enlineaBackendUrl}/api/Usuarios/GetAllPaginado?spinner=${spinner}&pageSize=${pageSize}&pageNumber=${pageNumber}&maxPages=${maxPages}`;
    for (const a in filterOptions) {
      if (filterOptions[a] !== undefined) {
        url += `&${this.capitalizeFirstLetter(a)}=${filterOptions[a]}`;
      }
    }

    return this.http.get(url);
  }

  public getInfoClienteActual(overwrite = false, spinner = true): Observable<Usuario> {
    if (!this.informacionUsuario || overwrite) {
      if (!this.informacionUsuario) this.informacionUsuario = new ReplaySubject(1);

      this.http.get<Usuario>(`${enlineaBackendUrl}/api/Usuarios?spinner=${spinner}`).subscribe((results) => {
        if (results.metadata) {
          results.compania = results.metadata.codigoPrestador;
          results.companiaSucursal = results.metadata.sucursal;
          results.companiaNombre = results.metadata.empresa;
          if (results.email.indexOf("@") < 0) results.identificacion = results.metadata.userCode;
        }
        this.isUserLoaded = true;
        this.informacionUsuario.next(results);
      });
    }

    return this.informacionUsuario;
  }
  public GetAllUsuarios(): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/Usuarios/GetAll`);
  }
  public GetUsuarios(id: unknown): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/Usuarios/GetColaboradores/${id}`);
  }
  public GetNewUsuarioCode(id: unknown): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/Usuarios/ObtenerCodigoUsuarioIntermediario/${id}`, { responseType: "text" });
  }
  public getUsuario(userId: unknown): Observable<Usuario> {
    return this.http.get<Usuario>(`${enlineaBackendUrl}/api/Usuarios/${userId}`);
  }

  public getUserProducts(): Observable<UserProduct[]> {
    return this.http.get<UserProduct[]>(`${enlineaBackendUrl}/api/Products`).pipe(
      map((userProductArray: UserProduct[]) => {
        return userProductArray.map((userProduct) => {
          userProduct.url = this.mapProductRoute(userProduct.description);
          userProduct.nombre = this.mapProductNombre(userProduct.description);
          return userProduct;
        });
      })
    );
  }

  private mapProductRoute(product: string): string {
    switch (product) {
      case "Ars":
        return "/portales/dashboard/salud";
      case "Seguros":
        return "/portales/dashboard/rgvsi";
      case "AFI":
        return "/portales/dashboard/afi";
      case "VIPE":
        return "/portales/rgvsi/dashboard/retiro";
      default:
        return "/portales";
    }
  }

  private mapProductNombre(product: string): string {
    switch (product) {
      case "Ars":
        return "Salud Local";
      case "Seguros":
        return "Riesgos Generales";
      case "AFI":
        return "Fondos de Inversión";
      case "VIPE":
        return "VIPE";
      default:
        return "/portales";
    }
  }

  //POST
  public PostUserAllDatabases(cliente: unknown): Observable<any> {
    return this.http.post<Usuario>(`${enlineaBackendUrl}/api/Usuarios/CreateUserAllProviders`, cliente);
  }

  public crearUsuariosMultiples(usuarios: unknown): Observable<any> {
    return this.http.post<Usuario>(`${enlineaBackendUrl}/api/Usuarios/CrearUsuarioMultiple`, usuarios);
  }

  public updateUser(cliente: unknown): Observable<any> {
    return this.http.patch(`${enlineaBackendUrl}/api/Usuarios`, cliente);
  }
  public bloquear(userGuid: string | number): Observable<any> {
    return this.http.patch(`${enlineaBackendUrl}/api/users/Credentials/Lock/${userGuid}`, {});
  }
  public desbloquear(userGuid: string | number): Observable<any> {
    return this.http.patch(`${enlineaBackendUrl}/api/users/Credentials/Unlock/${userGuid}`, {});
  }
  public aprobar(identificacion: string): Observable<any> {
    return this.http.patch(`${enlineaBackendUrl}/api/usuarios/Credenciales/Aprobar/${identificacion}`, {});
  }
  public desaprobar(identificacion: string): Observable<any> {
    return this.http.patch(`${enlineaBackendUrl}/api/usuarios/Credenciales/Anular/${identificacion}`, {});
  }

  public baseEliminar(identificacion: unknown): Observable<any> {
    return this.http.delete(`${enlineaBackendUrl}/api/Usuarios/${identificacion}`);
  }

  public reiniciarContrasena(userId: string | number, sendEmail: boolean = true): Observable<any> {
    return this.http.post(`${enlineaBackendUrl}/api/admin/users/Credentials/ResetPassword/${userId}?sendEmail=${sendEmail}`, {});
  }

  public switchProfile(profileId: number): Observable<any> {
    return this.http.post(`${enlineaBackendUrl}/api/Usuarios/ProfileSwitch/${profileId}`, {});
  }
  //DELETE
  public delete(username: string): Observable<any> {
    return this.http.delete(`${enlineaBackendUrl}/api/Usuarios/${username}`);
  }

  public deleteCPUser(username: string): Observable<boolean> {
    return this.http
      .delete(`${refundAttachmentsBackendUrl}/UserInformation/DeleteUser/${username}`, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.status === 200;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  public obtenerPrestadorDelUsuario(codigoPrestador: unknown): Observable<any> {
    return this.http.get<Prestador[]>(
      `${enlineaBackendUrl}/api/Prestador/ObtenerUsuariosDelPrestador?codigoPrestador=${codigoPrestador}`
    );
  }

  public deleteCache(): Observable<void> {
    return this.http.delete<void>(`${enlineaBackendUrl}/api/usuarios/cache`);
  }

  //GET
  public getUserToAssignPermissions(term: string = ""): Observable<Usuario[]> {
    if (term)
      return this.http.get<Usuario[]>(`${enlineaBackendUrl}/api/Usuarios/UsuarioPorBusqueda?spinner=false&busqueda=${term}`).pipe(
        map((resultados) => {
          return resultados;
        })
      );
    else return of([]);
  }

  //POST
  public copiarPermisosDeUsuarios(permisos: unknown): Observable<any> {
    return this.http.post(`${enlineaBackendUrl}/api/Usuarios/ClonarRolesUsuarios`, permisos);
  }

  public syncUser(): Observable<void> {
    return this.http.post<void>(`${enlineaBackendUrl}/api/users/sync`, {});
  }
}
