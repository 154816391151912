import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { UserAuthenticatedGuard } from "@shared/Guards/user-authenticated.guard";
import { UserPermissionGuard } from "@shared/Guards/user-permissions.guard";

import { GenericErrorComponent } from "./ErrorPages/GenericError/generic-error.component";
import { NotFoundComponent } from "./ErrorPages/NotFound/notfound.component";
import { SessionExpiredComponent } from "./ErrorPages/SessionExpired/session-expired.component";
import { UnathorizedComponent } from "./ErrorPages/Unathorized/unathorized.component";
import { RedirectLoginComponent } from "./RedirectLogin/redirect-login.component";
import { ResetPasswordComponent } from "./ResetPassword/reset-password.component";

const NOT_FOUND_MESSAGE_TITLE = "Página no encontrada";
const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "redirect",
    data: {
      title: "En Línea",
    },
  },
  {
    path: "redirect",
    component: RedirectLoginComponent,
  },
  {
    path: "portales",
    loadChildren: () => import("./Portales/portales.module").then((m) => m.PortalesModule),
    canActivate: [UserAuthenticatedGuard, UserPermissionGuard],
  },
  {
    path: "afi/formulario-de-vinculacion",
    loadChildren: () =>
      import("./AFI/formulario-de-vinculacion/formulario-de-vinculacion.module").then((m) => m.FormularioDeVinculacionModule),
  },
  {
    path: "salud/buscador-prestadores",
    loadChildren: () => import("./SaludLocal/ProvidersFinder/providers-finder.module").then((m) => m.ProvidersFinderModule),
  },
  {
    path: "solicitud-usuario",
    loadChildren: () => import("./SolicitudUsuario/solicitud-usuario.module").then((m) => m.SolicitudUsuarioModule),
  },
  {
    path: "afi/creacion-usuarios",
    loadChildren: () =>
      import("./AFI/personal-customer-creation/personal-customer-creation.module").then((m) => m.PersonalCustomerCreationModule),
  },
  {
    path: "cotizador-autos",
    loadComponent: () =>
      import("./RGVSI/Quoters/PublicCarQuoter/public-car-quoter.component").then((c) => c.PublicCarQuoterComponent),
  },
  {
    path: "reclamaciones/propiedades",
    loadChildren: () => import("./Reclamaciones/Propiedades/reclamacion-propiedades.module").then((m) => m.PropertyClaimsModule),
  },
  {
    path: "afi/calculadora-inversion",
    loadChildren: () =>
      import("./AFI/Calculadora-de-inversion/calculadora-de-inversion.module").then((m) => m.CalculadoraDeInversionModule),
  },
  {
    path: "errorgenerico",
    component: GenericErrorComponent,
    data: {
      title: "Error Genérico",
    },
  },
  {
    path: "unathorized",
    component: UnathorizedComponent,
    canActivate: [UserAuthenticatedGuard],
    data: {
      title: "No Autorizado",
    },
  },
  {
    path: "aviso-legal",
    loadChildren: () => import("./AvisoLegal/aviso-legal.module").then((m) => m.AvisoLegalModule),
  },
  {
    path: "rgvsi/cliente-produccion-diaria",
    loadChildren: () => import("./PolicyDownloads/policy-downloads.module").then((m) => m.PolicyDownloadsModule),
  },
  {
    path: "traspaso",
    loadChildren: () => import("./SaludLocal/TransferRequest/transfer-request.module").then((m) => m.TransferRequestModule),
  },
  {
    path: "cotizador-viajes",
    loadChildren: () => import("./RGVSI/Quoters/TravelQuoter/travel-quoter.module").then((m) => m.TravelQuoterModule),
  },
  {
    path: "resetpassword/:usuario",
    pathMatch: "full",
    component: ResetPasswordComponent,
    data: {
      title: "Reinicia Tu Contraseña",
    },
  },
  {
    path: "notfound",
    component: NotFoundComponent,
    data: {
      title: NOT_FOUND_MESSAGE_TITLE,
    },
  },
  {
    path: "session-expired",
    component: SessionExpiredComponent,
    data: {
      title: "Sesión Expirada",
    },
  },
  {
    path: "notfound",
    component: NotFoundComponent,
    data: {
      title: NOT_FOUND_MESSAGE_TITLE,
    },
  },
  {
    path: "**",
    component: NotFoundComponent,
    data: {
      title: NOT_FOUND_MESSAGE_TITLE,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: "top",
      // relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
