import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";

@Component({
  selector: "uni-step-header",
  templateUrl: "./step-header.component.html",
})
export class UniStepHeaderComponent {
  @Input() public label: string;
  @Input() public index: number;
  @Input() public active: boolean = false;
  @Input() public showIndex: boolean = true;
  @Input() public dataTarget: string;

  @Output() public clickStep = new EventEmitter<number>();

  @HostBinding("class.step") public step = true;
  @HostBinding("attr.data-target") public get dataTargetId(): string {
    return "#" + this.dataTarget;
  }

  public onClickStep(index: number): void {
    this.clickStep.emit(index - 1);
  }
}
