import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Modal } from '../../../shared/Entities/General/modal.entity';

@Component({
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmGeneral implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    modal: Modal;

    public onClose: Subject<boolean>;

    constructor(public bsModalRef: BsModalRef) {

    }

    ngOnInit() {
        this.onClose = new Subject();

        if (!this.modal)
            this.modal = new Modal();

        this.modal.trustedHtml = this.modal.body;
    }

    ok() {
        this.onClose.next(true);
        this.bsModalRef.hide();
    }
    cancel() {
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            if (!subscription.closed)
                subscription.unsubscribe();
        });
    }
}