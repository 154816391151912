import { Component, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
  selector: "uni-step",
  template: `<ng-template><ng-content></ng-content></ng-template>`,
})
export class UniStepComponent {
  /**
   *  Specify the label or title of the current step
   */
  @Input() public label: string;

  @ViewChild(TemplateRef, { static: true }) public content: TemplateRef<any>;
}
