<footer id="page-footer" class="footer-general">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <p class="my-3">
          <span class="d-block d-md-inline"
            >© {{ currentYear }} Grupo Universal. <br class="mobile-break" />
            Todos los derechos reservados.
          </span>
          <a class="text-primary" [routerLink]="['/aviso-legal/terminos-uso']">T&eacute;rminos de Uso</a> |
          <a class="text-primary" [routerLink]="['/aviso-legal/politicas-privacidad']">Privacidad</a>
        </p>
      </div>
    </div>
  </div>
</footer>
