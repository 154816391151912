import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "../ErrorPages/NotFound/notfound.component";

import { AvisoLegalComponent } from "./aviso-legal.component";
import { TerminosUsoComponent } from "./TerminosUso/terminos-uso.component";
import { PoliticasPrivacidadComponent } from "./PoliticasPrivacidad/politicas-privacidad.component";

const routes: Routes = [
  {
    path: "",
    component: AvisoLegalComponent,
    children: [
      {
        path: "terminos-uso",
        component: TerminosUsoComponent,
      },
      {
        path: "politicas-privacidad",
        component: PoliticasPrivacidadComponent,
      },
      {
        path: "**",
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AvisoLegalRoutingModule {}
