import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/shared/Services/auth.service";

import { Unsubscriber } from "@shared/Components/Unsubscriber/unsubscriber.component";

@Component({
  selector: "app-redirect-login",
  templateUrl: "./redirect-login.component.html",
  styleUrls: ["./redirect-login.component.scss"],
})
export class RedirectLoginComponent extends Unsubscriber implements AfterViewInit {
  constructor(private authService: AuthService, private router: Router) {
    super();
  }
  private viewInitMethodCounter: number = 0;

  public ngAfterViewInit(): void {
    this.anotherSubscription = this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.viewInitMethodCounter++;
        this.onLoginSuccess();
      }
    });
  }

  public login(): void {
    this.authService.login();
  }

  public onLoginSuccess(): void {
    const redirectUrl = this.authService.getRedirectUrl();
    if (redirectUrl) {
      if (this.viewInitMethodCounter === 2) this.authService.clearRedirectUrl();
      this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
    } else {
      this.router.navigateByUrl("/portales", { replaceUrl: true });
    }
  }

  public requestBusinessAccess(): void {
    this.router.navigateByUrl("/solicitud-usuario");
  }
}
