<section
  [class.success-modal]="modal.type === 'success'"
  [class.warning-modal]="modal.type === 'warning'"
  [class.danger-modal]="modal.type === 'danger'"
  [class.info-modal]="modal.type === 'info'"
>
  <div class="modal-header">
    <h4 uni-heading [color]="modal.type" class="modal-title">{{ modal.title }}</h4>

    <button uni-icon-button variant="muted" aria-label="Cerrar" (click)="cancel()">
      <uni-icon name="x"></uni-icon>
    </button>
  </div>
  <div class="modal-body" [innerHTML]="modal.trustedHtml"></div>

  <div class="modal-footer">
    ¿Deseas continuar?

    <button uni-button fill="outline" variant="warning" class="btn-small-outline-confirm" (click)="cancel()">NO</button>
    <button uni-button class="btn-small-confirm" (click)="ok()">SI</button>
  </div>
</section>
