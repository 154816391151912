import { AfterViewInit, Component, ContentChildren, ElementRef, HostListener, Input, Optional, QueryList } from "@angular/core";
import Stepper from "bs-stepper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FormStepperService } from "@shared/Services/form-stepper.service";
import { ScrollService } from "@shared/Services/scroll.service";

import { UniStepComponent } from "./step/step.component";

let stepperId = 1;

@Component({
  selector: "uni-stepper",
  templateUrl: "./stepper.component.html",
})
export class UniStepperComponent implements AfterViewInit {
  /**
   * unique identifier of the stepper
   */
  private _stepperId = `uni-stepper-${stepperId++}`;
  @Input() public set stepperId(value: string) {
    this._stepperId = value;
  }

  public get stepperId(): string {
    return this._stepperId;
  }

  @ContentChildren(UniStepComponent, { descendants: true }) public steps = new QueryList<UniStepComponent>();

  /**
   *
   * @ignore
   */
  private stepper: Stepper;

  /**
   * the index of the displaying step
   */
  public currentIndex: number = 0;

  /**
   * If `false` the stepper indexes will hide
   */
  @Input() public showIndex: boolean = true;

  /**
   * If `false` you can change the step clicking in the header
   */
  @Input() public linear: boolean = true;

  /**
   * If `false` the stepper won't be updated when the form stepper service change the step
   */
  @Input() public automaticUpdate: boolean = true;

  /**
   * If `false` the stepper won't show the confirmation dialog when the user try to go to close the stepper
   */
  @Input() public showDeactivaveWarning = true;

  @Input() public fullWidth: boolean = false;

  constructor(
    private readonly elementRef: ElementRef,
    private scrollService: ScrollService,
    @Optional() private formStepperService: FormStepperService
  ) {}

  public ngAfterViewInit(): void {
    const stepperEl = this.elementRef.nativeElement.querySelector(`#${this.stepperId}`);
    this.stepper = new Stepper(stepperEl, {
      animation: true,
      linear: this.linear,
    });

    stepperEl.addEventListener("show.bs-stepper", (event) => {
      this.currentIndex = event.detail.indexStep;
      this.scrollService.scrollTo(".bs-stepper-header");
    });

    if (this.formStepperService && this.automaticUpdate) {
      this.formStepperService.currentStep.subscribe(({ index }) => {
        this.stepper.to(index + 1);
      });
    }
  }

  /**
   * update stepper current step to next step
   */
  public next(): void {
    this.stepper.next();
    this.automaticUpdate && this.formStepperService?.nextStep();
  }

  /**
   * set stepper current step to previous step
   */
  public previous(): void {
    this.stepper.previous();
    this.automaticUpdate && this.formStepperService?.previousStep();
  }

  /**
   * set stepper to specify step
   * @param index step to move to
   */

  public to(index: number): void {
    this.stepper.to(index);
    this.automaticUpdate && this.formStepperService?.toStepByIndex(index);
  }

  /**
   * set stepper current step to inital
   */
  public reset(): void {
    this.stepper.reset();
    this.automaticUpdate && this.formStepperService?.toStepByIndex(0);
  }

  public get currentLabel(): string {
    return this.steps.get(this.currentIndex)?.label;
  }

  @HostListener("window:beforeunload")
  public canDeactivate(): Observable<boolean> | boolean {
    if (!this.linear || !this.showDeactivaveWarning) return true;

    if (this.formStepperService) {
      return this.formStepperService.progress.pipe(map((progress) => progress > 0));
    }
    return this.currentIndex === 0 || this.currentIndex === this.steps.length - 1;
  }
}
