import { Component } from "@angular/core";

@Component({
  selector: "app-terminos-uso",
  templateUrl: "./terminos-uso.component.html",
  styleUrls: ["./terminos-uso.component.scss"],
})
export class TerminosUsoComponent {
  public readonly sections = [
    { title: "Acceso del Sitio Web", id: "acceso-del-sitio-web" },
    { title: "Utilización del Sitio Web", id: "utilizacion-del-sitio-web" },
    {
      title: "Utilización del contenido informativo y servicios y procesos transaccionales disponibles en el Sitio Web",
      id: "utilizacion-del-contenido-informativo-y-servicios-y-procesos-transaccionales-disponibles-en-el-sitio-web",
    },
    { title: "Conducta del Visitante y/o Usuario", id: "conducta-del-visitante-y-o-usuario" },
    { title: "Devolución o Cancelación de transacciones", id: "devolucion-o-cancelacion-de-transacciones" },
    { title: "Uso de nombres de Usuarios y contraseñas", id: "uso-de-nombres-de-usuarios-y-contraseñas" },
    {
      title: "Uso del correo electrónico y de la aplicación de Live Chat",
      id: "uso-del-correo-electronico-y-de-la-aplicacion-de-live-chat",
    },
    { title: "Sugerencias", id: "sugerencias" },
    { title: "Contenidos de terceros", id: "contenidos-de-terceros" },
    { title: "Modificaciones en el Sitio Web de Grupo Universal", id: "modificaciones-en-el-sitio-web-de-grupo-universal" },
    { title: "Fallos", id: "fallos" },
    { title: "Servidor seguro", id: "servidor-seguro" },
    { title: "Discrepancias de documentos en versión electrónica", id: "discrepancias-de-documentos-en-version-electronica" },
    { title: "Propiedad intelectual", id: "propiedad-intelectual" },
    { title: "Asunción de riesgos", id: "asuncion-de-riesgos" },
    { title: "Exclusión de responsabilidad y garantías", id: "exclusion-de-responsabilidad-y-garantias" },
    { title: "Límite de responsabilidad", id: "limite-de-responsabilidad" },
    { title: "Exoneración de responsabilidad", id: "exoneracion-de-responsabilidad" },
    { title: "Indemnización", id: "indemnizacion" },
    { title: "Control", id: "control" },
    { title: "Resolución", id: "resolucion" },
    { title: "Vigencia y notificación de cambios", id: "vigencia-y-notificación-de-cambios" },
    { title: "Conocimiento y consentimiento", id: "conocimiento-y-consentimiento" },
    { title: "Renuncia", id: "renuncia" },
    { title: "Totalidad del acuerdo y reserva de derechos", id: "totalidad-del-acuerdo-y-reserva-de-derechos" },
    { title: "Legislación aplicable", id: "legislacion-aplicable" },
  ];
}
